(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/card-puff/views/card-puff.js') >= 0) return;  svs.modules.push('/components/banner_layouts/card-puff/views/card-puff.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.card_puff=_svs.banner_layouts.card_puff||{};
_svs.banner_layouts.card_puff.templates=_svs.banner_layouts.card_puff.templates||{};
svs.banner_layouts.card_puff.templates.card_puff = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"banner-card-puff-item  banner-card-puff bg-white f-content\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"targetOffer") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":65}}})) != null ? stack1 : "")
    + "\n    data-impression=true data-impression-tracking=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"trackingString") || (depth0 != null ? lookupProperty(depth0,"trackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"trackingString","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":69}}}) : helper)))
    + "\">\n    <div class=\"banner-card-top-image\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"0") : stack1),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"banner-card-body padding-left-1 padding-right-1\">\n        <div class=\"margin-bottom-half f-center\">\n            <span class=\"block f-800 f-bold fc-navy margin-0 banner-card-lineheight-compact\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"heading") || (depth0 != null ? lookupProperty(depth0,"heading") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"heading","hash":{},"data":data,"loc":{"start":{"line":16,"column":93},"end":{"line":16,"column":106}}}) : helper))) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div class=\"margin-bottom-half f-center\">\n            <span class=\"block f-content fc-navy margin-0 banner-card-content-lineheight-compact\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":19,"column":98},"end":{"line":19,"column":111}}}) : helper))) != null ? stack1 : "")
    + "</span>\n        </div>\n    </div>\n</div>\n<div class=\"banner-card-bottom-container\">\n    <div class=\"padding-lg-left-2 padding-lg-right-2 padding-md-left-1 padding-md-right-1\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-banner_actions-link_button-link_button"),depth0,{"name":"components-banner_actions-link_button-link_button","hash":{"clickTrackingString":(depth0 != null ? lookupProperty(depth0,"trackingString") : depth0),"action":((stack1 = (depth0 != null ? lookupProperty(depth0,"callToAction") : depth0)) != null ? lookupProperty(stack1,"actionConfig") : stack1)},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":20},"end":{"line":4,"column":26}}}) : helper)))
    + "\"\n        data-cmsType=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":37}}}) : helper)))
    + "\"\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"renderCrmAttributes")||(depth0 && lookupProperty(depth0,"renderCrmAttributes"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"targetOffer") : depth0),{"name":"renderCrmAttributes","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":58}}}));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),depth0,{"name":"components-content-image_v2-image","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"paper",{"name":"ui","hash":{"branding":"sport","class":"banner-edit-paper banner-card-paper clip paper-link"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_layouts-card_puff-card_puff'] = svs.banner_layouts.card_puff.templates.card_puff;
})(svs, Handlebars);


 })(window);