(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/card-puff/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/card-puff/assets/javascripts/render-component.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
const {
  core
} = isServer && require('trinidad-core') || {};
const format = isServer ? core.components.require('utils', 'format').api : svs.utils.format;
const layoutType = 'card_puff';

const escapeHtml = input => {
  const replacingChars = match => ({
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;'
  })[match];
  return input === null || input === void 0 ? void 0 : input.replace(/[&<>"]/g, replacingChars);
};
const groupNumbers = function (text) {
  let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let wrapper = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '<span class="inline-block">$&</span>';
  const output = (text === null || text === void 0 ? void 0 : text.replaceAll(/\d+(?:[ .,:-]\d+)*/g, wrapper)) || '';
  return config.escapeHtml ? escapeHtml(output) : output;
};
const renderComponent = async (model, widget, position, req, res) => {
  var _svs, _model$contentType;
  const hbsCompiled = isServer ? global.internalInstances.get('hbs').cache['components-banner_layouts-card_puff-card_puff'] : svs.banner_layouts.card_puff.templates.card_puff;
  const {
    callToActions,
    images,
    id,
    layoutConfig,
    targetOffer,
    title
  } = model;
  const features = isServer ? res.locals.features : (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features;
  const contentType = (model === null || model === void 0 || (_model$contentType = model.contentType) === null || _model$contentType === void 0 ? void 0 : _model$contentType.split(':').pop()) || 'banner';
  const crm = targetOffer || '';
  const trackingStringTitle = format.safeString(title) || title;
  const trackingString = "".concat(widget.widgetType, "/").concat(widget.trackingId, "/").concat(position, "/small-article/").concat(trackingStringTitle);
  const isAdmin = isServer ? req.userSession.hasRole(req.userSession.roles.INTERNAL) : svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);

  const defaultTheme = layoutConfig.btnTheme === undefined || layoutConfig.btnTheme === 'focus';
  const actionViewConfig = {
    block: true,
    focus: defaultTheme,
    inverted: layoutConfig.btnTheme === 'inverted',
    transparent: layoutConfig.btnTheme === 'transparent'
  };
  const getCallToAction = function (action) {
    let viewConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    action = _objectSpread(_objectSpread({}, action), {}, {
      actionConfig: _objectSpread(_objectSpread({}, action.actionConfig), {}, {
        viewConfig
      })
    });
    return action;
  };
  const callToAction = getCallToAction(callToActions[0], actionViewConfig);
  const heading = groupNumbers(layoutConfig.header);
  const content = groupNumbers(layoutConfig.content);
  return hbsCompiled(_objectSpread(_objectSpread({}, layoutConfig), {}, {
    crm,
    callToAction,
    contentType,
    features,
    id,
    content,
    heading,
    images,
    isAdmin,
    targetOffer,
    trackingString
  })) || '';
};
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_layouts[layoutType] = {
    renderComponent
  };
}

 })(window);